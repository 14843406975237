<template>
  <policy>
    <terms-and-conditions></terms-and-conditions>
  </policy>
</template>

<script>
import Policy from "@/layouts/Policy.vue"
import TermsAndConditions from "@/components/policy/TermsAndConditions.vue"

export default {
  components: { Policy, TermsAndConditions }
}
</script>
